import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'Listing Document Storage',
    text: 'Ability to store and manage files related to property listings.',
  },
  {
    title: 'Flyer Creation',
    text: 'Capability to create visually appealing flyers for listings.',
  },
  {
    title: 'Flyer Distribution',
    text: ' Sending flyers to potential buyers/tenants using a customer database',
  },
  {
    title: 'Listing Aging',
    text: 'Tracking the number of days a listing has been active to assess its status and performance.',
  },
  {
    title: 'EstatePro Dashboard',
    text: 'Features a section that displays upcoming tasks and deadlines related to each listing along with wide-ranging features of Estatepro.',
  },
  {
    title: 'Meeting Notes',
    text: 'A dedicated section to record meeting notes with property owners or stakeholders.',
  },
  {
    title: 'Activity Logging',
    text: 'Ability to log and document activities related to a listing, such as follow-up calls with potential buyers, including details, dates, and discussed topics.',
  },
];

const Features = () => (
  <div className="estatepro__features section__padding" id="features">
    <div className="estatepro__features-heading">
      <h1 className="darkorange__text">WHY SETTLE FOR OUTDATED, CLUNKY SOFTWARE OR MANUAL PROCESSES WHEN YOU CAN EXPERIENCE THE EFFICIENCY AND GROWTH POTENTIAL OF ESTATEPRO?</h1>
      <p>Request Early Access to Get Started</p>
    </div>
    <div className="estatepro__features-container blue__text">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;