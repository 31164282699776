import React from 'react';
import './tools.css';

const tools = () => (
  <div className="estatepro__tools section__padding" id="tools">
    
    <div className="estatepro__tools-content">
      <h4>Request Early Access to Get Started</h4>
      <h1 className="gradient__text">EstatePro is the ultimate Real Estate CRM Solution</h1>
      <p>Revolutionize Your Real Estate Business with EstatePro - Harness Cutting-Edge Tools, Maximize Efficiency, and Dominate the Market with Comprehensive Listing Management, Automated Lead Generation, Streamlined Communication, Data-Driven Analytics, and Seamless Integrations!</p>
    </div>
  </div>
);

export default tools;