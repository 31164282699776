import React from "react";
import { About, Contact, Features, Footer, Header, Tools } from "./containers";
import { Brand, CTA, Navbar } from "./components";
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
      </div>
      <div className="gradient__bg-header">
        <Header />
        {/* <Brand /> */}
        <About />
        <Features />
        <Tools />
        <CTA />
        {/* <Contact /> */}
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default App;
