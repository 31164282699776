import React from 'react';
import './cta.css';

const CTA = () => {
  const handleGetStartedClick = () => {
    const recipientEmail = 'estatepro.inq@gmail.com';
    const subject = 'Request for Early Access - EstatePro';
    const message = 'Dear Sir/Madam,\n\nI am interested in EstatePro and would like to request early access. Please provide me with further details on how to get started.\n\nThank you.\n\nBest regards,\n[Your Name]';

    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.open(mailtoLink, '_blank');
  };

  return (
    <div className="estatepro__cta">
      <div className="estatepro__cta-content">
        <p>Request Early Access to Get Started</p>
        <h3>Sign Up and Register Today To Get Early Access To EstatePro's Intelligence and Unparalleled Efficiency</h3>
      </div>
      <div className="estatepro__cta-btn">
        <button type="button" onClick={handleGetStartedClick}>
          Get Started
        </button>
      </div>
    </div>
  );
};

export default CTA;
