import React from "react";
import "./header.css";
import header3 from "../../assets/header3.png";

const Header = () => {
  const handleLearnMoreClick = () => {
    const recipientEmail = "estatepro.inq@gmail.com";
    const subject = "Request for Details about EstatePro - Early Access";
    const message = "Dear Sir/Madam,\n\nI am interested in learning more about EstatePro and would like to request early access. Please provide me with further details and any necessary steps to get started.\n\nThank you.\n\nBest regards,\n[State your Name Here]";

    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.open(mailtoLink, "_blank");
  };

  return (
    <div className="estatepro__header" id="home">
      <div className="estatepro__header-content">
        <h1 className="gradient__text">EstatePro</h1>
        <p>
          Transform Your Real Estate Game with EstatePro: The Future of CRM
          Solutions
        </p>

        <div className="estatepro__header-content__input">
          <input
            // className="gradient__bg-header"
            type="email"
            placeholder="Your Email Address"
          />
          <button type="button" onClick={handleLearnMoreClick}>
            Learn More
          </button>
        </div>
      </div>
      <img src={header3} alt="EstatePro" />
    </div>
  );
};

export default Header;
