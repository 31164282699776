import React from 'react';
import './footer.css';

const Footer = () => {
  const handleRequestAccessClick = () => {
    const recipientEmail = 'estatepro.inq@gmail.com';
    const subject = 'Request for Early Access - EstatePro';
    const message = 'Dear Sir/Madam,\n\nI am interested in EstatePro and would like to request early access. Please provide me with further details on how to get started.\n\nThank you.\n\nBest regards,\n[Your Name]';

    const mailtoLink = `mailto:${recipientEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(message)}`;
    window.open(mailtoLink, '_blank');
  };

  return (
    <div className="estatepro__footer section__padding">
      <div className="estatepro__footer-heading">
        <h1>EstatePro: Where Real Estate Mastery Meets Unmatched Efficiency</h1>
      </div>

      <div className="estatepro__footer-btn">
        <a href="#tools" onClick={handleRequestAccessClick}>
          <p>Request Early Access</p>
        </a>
      </div>

      <div className="estatepro__footer-links">
        <div className="estatepro__footer-links_logo">
          <p>EstatePro 2023 <br /> All Rights Reserved</p>
        </div>
        <div className="estatepro__footer-links_div">
          <h4>Links</h4>
          <p>Facebook</p>
          <p>LinkedIn</p>
          <p>Twitter</p>
          <p>Contact Us</p>
        </div>
        <div className="estatepro__footer-links_div">
          <h4>Company</h4>
          <p>Terms & Conditions </p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className="estatepro__footer-links_div">
          <h4>Get in touch</h4>
          <p>estatepro.inq@gmail.com</p>
        </div>
      </div>

      <div className="estatepro__footer-copyright">
        <p>@2023 EstatePro. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
