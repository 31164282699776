import React from "react";
import "./about.css";
import { Feature } from "../../components";

const About = () => {
  return(
    <div className="estatepro__about section__padding section__margin" id="about">
      <div className="estatepro__about-feature">
        <Feature title="What is EstatePro" text="EstatePro is a cutting-edge real estate CRM (Customer Relationship Management) software designed to streamline your sales process and take your business to new heights. With its comprehensive suite of features, EstatePro simplifies the management of listings, clients, and communications, allowing you to focus on what really matters – closing deals and growing your business."/>
      </div>
      <div className="estatepro__about-heading">
        <h1 className="gradient__textorangewhite">JOIN THE FUTURE OF REAL ESTATE CRM SOLUTIONS TODAY AND SEE THE DIFFERENCE FOR YOURSELF</h1>
        <p>What you can do with EstatePro</p>
      </div>
      <div className="estatepro_about_container">
        <Feature title="Comprehensive Listing Management" text="Easily manage and organize all your listings in one place. Quickly access and share l isting information with clients and colleagues. Customize listings with photos, videos, and detailed descriptions to attract more potential buyers"/>
        <Feature title="Client and Lead Management" text="Keep track of client information and communication history for better client relationships. Automate lead generation and nurturing to save time and boost conversion rates." />
        <Feature title="Communication Logs and Document Management" text=" Log all client communication for a clear record of interactions. Securely store and manage documents such as contracts, agreements, and flyers. Easily access and share documents with clients, colleagues, and vendors" />
        <Feature title="Data Analytics and Reporting" text="Gain valuable insights into your business performance with customizable analytics and reporting. Use data to identify trends, optimize processes, and make data-driven decisions. Track key metrics such as lead generation, conversion rates, and revenue to measure success and plan for growth." />
        <Feature title="Integration with Third-Party Tools" text="Simplify workflows and reduce manual data entry with automatic synchronization. Access a wide range of tools and services to enhance your business operations and capabilities." />


      </div>
    </div>
  );
};

export default About;
